<template>
  <HomePage />
</template>

<script>
  import HomePage from '@/components/HomePage'

  export default {
    components: {
      HomePage
    }
  }
</script>
