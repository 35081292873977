<template>
 <v-container fluid grid-list-xl class="home-page" ma-0 pa-0>
   <v-layout
     align-center justify-center row wrap fill-height
   >
     <v-flex
      xs7
     >
      <v-layout
              fill-height
      >
        <v-flex 
          xs12
        >
          <v-layout 
            name="panel-izquierdo" 
            column wrap
          >
            <v-flex xs12 class-debug="red white--text">
              <h1 class="display-1 font-weight-bold blue-grey--text">
                {{ $t("home.welcome") }}
              </h1>
            </v-flex>
            <v-flex xs12 class-debug="green white--text">
          <p>
            {{ $t("home.p1")}}.
          </p>
          <p>
            {{ $t("home.p2") }} 
            <router-link to="/library" class="blue--text">{{ $t("home.l1") }}</router-link> 
            {{$t("home.p3")}} 
          </p>
          <p>
            {{ $t("home.p4") }}
            <router-link to="/about" class="blue--text">{{ $t("home.l2") }}</router-link> 
            {{ $t("home.p5") }}
          </p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
     </v-flex>
     <v-flex
      xs4
     >
      <v-layout
        name="panel-imagen"
        text-xs-center
        fill-height
      >
        <v-flex 
          xs12
          class-debug="blue white--text"
          style="padding-left: 50pt;"
          class="trasnparent"
        >
          <a 
            :href="link"
            :enabled="!logo.match('png')" 
            :target="target"
            style="cursor: pointer; text-align: right;"
          >
            <v-img
              transition="scale-transition" origin="center center"
              :src="logo"
              :key="logo"
              :title="picTitle"
              class="my-3"
              contain
              height="375"
            >
            </v-img>
          </a>
        </v-flex>
      </v-layout>
     </v-flex>
   </v-layout>
 </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  export default {
    data: () => ({
      logo: require('@/assets/logo.png'),
      link: '/library',
      picTitle: 'Adventure Comics Nº 292',
      target: '_self',
      timer: null
    }),
    methods: {
      getNewPicture() {
        axios.get(`${this.currentHost}/legionthumb`)
        .then(result => {
          //console.log('Got random page: ', result.data)
          this.logo = `${this.currentHost}/page?at=${result.data}&page=0`
          this.picTitle = result.data
          this.link = `${this.currentHost}/reader.html?at=${result.data}&page=0&pages=80`
          this.target = '_blank'
          axios.get(`${this.currentHost}/item?at=${result.data}`)
          .then(r => {
            //console.log(`Size of the random issue: ${r.data.size}`)
            this.link = this.link.replace(/80$/, r.data.size)
          })
          .catch(e => console.log(e.message))
        })
        .catch(e => {
          //console.log(`ERROR: ${e.message}`)
          this.logo = require('@/assets/logo.png')
          this.picTitle = 'Adventure Comics Nº 292'
          this.link = '_self'
          this.target = '/library'
        })
      },
      setTimer() {
        this.timer = setInterval(this.getNewPicture, 10000)
      }
    },
    computed:{
      ...mapGetters(['getLocalHost','getCurrentHost','getRemoteHost']),
      currentHost() {
        if (window && window.location) {
          return this.getCurrentHost(window.location.href)
        } else {
          return 'https:/silvercomics.com.ar'
        }
      }
    },
    created() {
      //console.log('Setting interval for image renewing')
      this.getNewPicture()
      this.setTimer()
    },
    beforeDestroy() {
      if (this.timer) clearInterval(this.timer)
    }
  }
</script>

<style>
  .home-page h2 {
    color: silver;
  }

</style>


