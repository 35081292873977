<template>
  <v-app class="app">
    <v-navigation-drawer 
      app dark 
      class="light-blue lighten-2"
      width="200"
      v-model="drawer"
    >
      <v-list class="white--text">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-sub-title>
              Chess Club Classic 
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-spacer></v-spacer>
        <v-list-tile router to="/">
          <v-list-tile-action>
            <v-icon>home</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            {{ $t("app.home")  }}
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile 
          ref="lib_link_d" 
          router 
          :to="{name: 'libraryat', params: {at: libraryRoute}}"
          @click.prevent="$router.push(`/library/${libraryRoute}`)"
        >
          <v-list-tile-action>
            <v-icon>list</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            {{ $t("app.library")  }}
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile router to="/about">
          <v-list-tile-action>
            <v-icon>info</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            {{ $t("app.about")  }}
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar app dark class="light-blue darken-2">
      <v-toolbar-side-icon @click="drawer = !drawer">
      </v-toolbar-side-icon>

      <span>Silver Age Comics</span>
      <v-spacer></v-spacer>
      <span>
        <router-link class="px-3 py-2" to="/">{{ $t("app.home")  }}</router-link>
        <router-link
          ref="lib_link_n" 
          class="px-3 py-2" 
          :to="{name: 'libraryat', params: {at: libraryRoute}}"
          @click.prevent="$router.push(`/library/${libraryRoute}`)"
        >
            {{ $t("app.library")  }}</router-link>
        <router-link class="px-3 py-2" to="/about">{{ $t("app.about")  }}</router-link>
      </span>
      <v-spacer></v-spacer>
      <v-flex v-if="langItems">
        <label>{{ $t("app.language")}} &nbsp;&nbsp;&nbsp; </label>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              v-on="on"
            >
              {{ currentLocaleCaption }}
            </v-btn>
          </template>
          <v-list class="primary">
            <v-list-tile
              v-for="item in langItems"
              :key="item.value"
              @click="currentLocale = item.value"
            >
              <v-list-tile-title  
                :class="item.text === currentLocaleCaption ? 'pink--text text--lighten-3' : ''"
              >
                {{ item.text }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
     </v-flex>     
    </v-toolbar>

    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
    <v-footer v-if="visitors > 0" app dark color="deep-purple darken-4">
      <v-layout row wrap justify-space-around>
        <v-flex ml-3>
          {{ visitors }} &nbsp;&nbsp;&nbsp; {{ $t("app.visitors")}}
        </v-flex>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-flex style="text-align: right;" mr-3 >
          {{ $t("app.servertime") }}: &nbsp;&nbsp;&nbsp; 
          {{
          firebaseTime ? 
             firebaseTime.replace(' - ', '&nbsp;&nbsp;-&nbsp;&nbsp;') :
            ''
          }}
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import axios from 'axios'
//import {db, fbfunctions} from '@/firebase/init'
import firebase from 'firebase'
import functions from 'firebase/functions'
import {mapGetters} from 'vuex'

export default {
  name: "App",
  data: () => ({
    drawer: false,
    timeServer: 'https://us-central1-silvercomics-app.cloudfunctions.net/currentTime',
    visitors: -1,
    timer: null,
    firebaseTime: null,
  }),
  methods: {
    updateData() {
      axios.get(`${this.currentHost}/visitors`)
      .then(response => {
        this.visitors = response.data
        //console.log('Visitors: ', this.visitors)
      })
      .catch(err => console.log(err.message))

      let cTime = firebase.functions().httpsCallable('currentTime') 
      cTime({})
      .then(result => {
        //console.log(`currentTime respondió:\t${JSON.stringify(result.data)}`)
        this.firebaseTime = result.data
      })
      .catch(e => console.log(`ERROR en cloud function: ${e.message}`))
    },
    getLocaleCookie() {
      return this.$cookies.isKey('chosenLocale') ? 
        this.$cookies.get('chosenLocale') :
        false
    },
    getLocale() {
      const localeCookie = this.getLocaleCookie()
      if (localeCookie) {
        return localeCookie
      }
      if (!window || !window.navigator) {
        return 'en'
      }
      try {
        return window.navigator.language.slice(0, 2)
      } catch(e) {
        console.error(e.message)
        return 'en'
      }
    }
  },
  computed: {
    ...mapGetters(['getLocalHost', 'getRemoteHost', 'getCurrentHost']),
    libraryRoute: {
      cache: false,
      get() {
        return this.$store.getters.libraryRoute
      }
    },
    currentLocale: {
      cache: false,
      get() {
        return this.$i18n.locale 
      },
      set(locale) {
        if (this.$i18n) this.$i18n.locale = locale
        this.$cookies.set('chosenLocale', locale)
      } 
    },
    currentLocaleCaption: {
      cache: false,
      get() {
        if (!this.langItems) return "English"
        let item = this.langItems.find(i => i.value === this.currentLocale) 
        return item ? item.text : "English"
      }
    },
    langItems: {
      cache: false,
      get() {
        return [
          {value: 'en', text: this.$t("app.english")},
          {value: 'es', text: this.$t("app.spanish")},
          {value: 'fr', text: this.$t("app.french")},
          {value: 'it', text: this.$t("app.italian")},
          {value: 'pt', text: this.$t("app.portuguese")}
        ]
      }
    },
    currentHost() {
      if (window && window.location) {
        return window.location.href.match('localhost') ?
          this.getLocalHost :
          this.getRemoteHost
      } else {
        return this.getLocalHost
      }
    }
  },

  created() {
    this.updateData()
    this.timer = setInterval(this.updateData, 60000)
  } ,
  mounted() {
    // console.log(`Locale: ${this.$i18n.locale}`)
    const lang = this.getLocale()
    this.currentLocale = lang
  },
  beforeDestroy() {
    //console.log('Destroying app')
    if (this.timer) clearInterval(this.timer)
  }
}
</script>

<style>
  .app a {
    text-decoration: none;
    color: white;
    border-radius: 5px;
  }

  .app nav a:hover {
    color: black;
    background-color: lightgrey;
  }

  .app .router-link-exact-active {
    color: pink;
  }


</style>
