import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    localHost: 'http://localhost:20386',
    remoteHost: 'https://silvercomics.com.ar',
    library_route: '@'
  },
  mutations: {
    LIBRARY_ROUTE: (state, route) => state.library_route = route.replace(/@{2,}/g, '@')
  },
  actions: {
    setLibraryRoute: (store, route) => store.commit('LIBRARY_ROUTE', route)
  },
  getters: {
    getLocalHost: state => state.localHost,
    getRemoteHost: state => state.remoteHost,
    getCurrentHost: state => route => route.match('localhost') ?
        state.localHost :
        state.remoteHost,
    libraryRoute: state => state.library_route
  }
})
