<template>
  <v-container  grid-list-md light-blue lighten-5 text-xs-center class="library">
    <v-layout mb-3 text-xs-center wrap>
      <v-flex title blue-grey--text font-italic>
        <h1>{{ $t("library.heading") }}</h1>
      </v-flex>  
    </v-layout>
    <v-layout 
      v-if="path" d-flex mb-2 justify-center row 
      yellow lighten-5 pa-1 elevation-15 mb-4
      style="border-bottom: solid 1px steelblue;"
    >
      <v-flex 
        white--text 
        font-weight-black
        mx-1
        style="cursor: pointer; font-size: 12pt;"
        v-for="(bc, index) in crumbs" 
        :key="index"
        @click.stop="goto(bc.path)"
        :class="`${index % 2 === 0 ? 'red darken-3' : 'blue darken-3'} hover-grey`"
      >
          /{{ bc.name }} 
      </v-flex>
    </v-layout>
    <v-layout wrap text-xs-center justify-space-between align-center align-content-center>
      <v-flex
        xs12 sm6 md4 lg3 xl2
        v-for="dir in dirs" 
        :key="dir.path"
      >
        <v-card 
          elevation-12
          color="white--text"
          :width="`${191}px`"
          :height="`${108}px`"
        >
          <v-img
            :src="require('@/assets/img/supi_folder.jpg')"
            style="cursor: pointer;"
            @click="goto(dir.path)"
            :title="dir.name"
            contain
          >
            <v-container fill-height fluid>
              <v-layout row fill-height align-end>
                <v-flex xs12 flexbox title>
                    {{ dir.name | dots }}
                </v-flex>
              </v-layout>
            </v-container>  
          </v-img>
        </v-card>
      </v-flex>
    </v-layout>
    <v-spacer></v-spacer>
    <v-layout wrap text-xs-center justify-space-between class="mt-5">
      <v-flex
        xs12 sm6 md3
        v-for="comic in comics" 
        :key="comic.path"
      >
        <v-card 
        >
          <v-container fill-height fluid white>
            <v-layout fill-height>
              <v-flex xs12>
                <a 
                  :href="`${currentHost}/reader.html?page=0&at=${comic.path}&pages=${comic.size}`" 
                  target="_blank"
                  style="cursor: pointer;"
                >
                  <v-img 
                    transition="scale-transition" origin="center center"
                    :src="`${currentHost}/page?zoom=16&page=0&at=${comic.path}`"
                    :title="`${comic.name.replace(/\.cb[zr]/, '')} - ${comic.size} pages`"
                    width="100%"
                    contain
                  />
                </a>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

const decoratePath = path => `@${path.replace(/^\/+/, '').
                              replace(/\//g, '@').
                              replace(/\s/g, '_').
                              replace(/%20/g, '_').
                              replace(/@{2,}/g, '@')}`

const unDecoratePath = dpath => dpath.replace(/@/g, '/').replace(/_/g, ' ').replace(/^\/{2,}/,'/')

export default {
  name: "library",
  data: () => ({
    item: {path: '/', name: ''},
  }),
  filters: {
    dots: val => val.length > 20 ? `${val.slice(0, 20)}...` : val
  },
  computed: {
    ...mapGetters(['libraryRoute', 'getLocalHost', 'getRemoteHost', 'getCurrentHost']),
    currentHost() {
      return window && window.location ? 
        this.getCurrentHost(window.location.href) :
        this.getRemoteHost
    },
    path: {
      cache: false, 
      get() {
        return this.item ? this.item.path : '/'
      }
    },
    comics() {
      if (this.item && this.item.files) {
        return this.item.files.filter(f => this.isComic(f))
      } else {
        return []
      }

    },
    dirs() {
      if (this.item && this.item.files) {
        return this.item.files.filter(f => !this.isComic(f))
      } else {
        return []
      }
    },
    crumbs() {
      const joinSlash = array => array.join('/')
      const mapper = a => a.map((l, i) => ({name: l, path: ("/" + joinSlash(a.slice(0, i + 1))).replace(/\/{2,}/g, "/")}))
      const p = unDecoratePath(this.$route.path.replace('/library/', '')) || ''
      let breadcr = mapper(p.split('/'))
      if (breadcr.length === 2 && breadcr[0].name === '' && breadcr[1].name === '') breadcr = [breadcr[1]]
      return breadcr      
    },
  },
  watch: {
    $route: 'triggerLoadData',

    comics: function(currentCollection, prevCollection) {
       if (!this.item || !this.item.files) return
       this.item.files.forEach((element, index) => {
         if (!this.isComic(element)) return
         setTimeout(() => {
           axios.get(`${this.currentHost}/item?at=${element.path}`)
           .then(response => {
             //console.log('Size: ' + response.data.size)
             element.size = response.data.size
             this.item.files[index] = {...element}
           })
           .catch(err => console.error(err.message))
         }, 0)
       })
    },
  },
  methods: {
    ...mapActions(['setLibraryRoute']),
    goto(path) {
      this.$router.push(decoratePath(path))
    },
    triggerLoadData() {
      console.log("Refresh invoked by change of path")
      this.setLibraryRoute(decoratePath(this.$route.path.replace('/library/', '')))
      .then(this.loadData)
    },
    loadData() {
      axios.get(`${this.currentHost}/item?at=${unDecoratePath(this.$route.path).replace('/library','')}`)
      .then(response => {
        this.item = {...response.data}
        this.item.files = this.item.files.map( item => this.isComic(item) ? {...item, size: 80} : item)
      })
      .catch(err => console.log(err))
    },
    isComic: item => (item.type === 'rarfile' || item.type === 'zipfile'), 
  },
  mounted()  {
    window.librarian = this
    try {
      // this.item = {path:  unDecoratePath(this.$route.path).replace('/library/','')}
      this.triggerLoadData()
    }
    catch(e) {
      console.error("Error en 'mounted(): " , e.message)
    }
  }, 
}
</script>

<style>
  .library, .v-card {
    border-radius: 10px;
  }
  .hover-grey:hover {
    background: 'grey';
  }

</style>


