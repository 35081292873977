import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'
import i18n from './i18n'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
VueCookies.config('365d')

const firebaseConfig = {
  apiKey: "AIzaSyDxEgl8CdPBbuf0hhnKVESNEyrlgNyPXZk",
  authDomain: "silvercomics-app.firebaseapp.com",
  databaseURL: "https://silvercomics-app.firebaseio.com",
  projectId: "silvercomics-app",
  storageBucket: "",
  messagingSenderId: "1042302064002",
  appId: "1:1042302064002:web:8628f99fc1721e60"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig)


Vue.config.productionTip = false


window.xzylex = new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount('#app').$children[0]
